// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  Chart,
  GridLineOptions,
  PrimitiveArray,
  ChartOptions,
  DataItem
} from "billboard.js";
import { useMemo, useCallback, RefObject } from "react";
import { useIntl } from "react-intl";
import { zoom as billboardZoom } from "billboard.js";
import getZoomExtent from "../utils/getZoomExtent";

const useLineChartConfiguration = (
  data: any,
  mapToColumns: (data: any) => Array<[string, ...PrimitiveArray]>,
  chartContainerRef: RefObject<HTMLDivElement>,
  mapToLines?: (data: any) => GridLineOptions[],
  zoom?: boolean,
  tooltip?: boolean,
  tooltipContents?: (data: DataItem[]) => string
) => {
  const intl = useIntl();

  const getColumn = useCallback(
    (columns: Array<[string, ...PrimitiveArray]> | undefined, id: string) => {
      const filteredColumns = columns && columns.filter((c) => c[0] === id);
      return filteredColumns && filteredColumns[0];
    },
    []
  );

  const getLastPointDate = useCallback(
    (columns: Array<[string, ...PrimitiveArray]> | undefined) => {
      const column = getColumn(columns, "x");
      const lastDate = column && column[column.length - 1];
      return lastDate as string;
    },
    [getColumn]
  );

  const getNumberOfPoints = useCallback(
    (columns: Array<[string, ...PrimitiveArray]> | undefined, id: string) => {
      const column = getColumn(columns, id);
      return column ? column.length - 1 : 0;
    },
    [getColumn]
  );

  const zoomConfiguration = useMemo(() => {
    return zoom
      ? {
          enabled: billboardZoom(),
          extent: getZoomExtent(data),
          onzoomstart: () => {
            if (chartContainerRef.current) {
              const tooltip = chartContainerRef.current.querySelector(
                ".bb-tooltip-container"
              ) as HTMLDivElement;
              tooltip.style.display = "none";
            }
          }
        }
      : undefined;
  }, [zoom, data, chartContainerRef]);

  const configuration: ChartOptions = useMemo(() => {
    const columns = data ? mapToColumns(data) : undefined;
    const lines = mapToLines ? mapToLines(data) : undefined;
    const numberOfPoints = getNumberOfPoints(columns, "x");
    const lastDate = getLastPointDate(columns);

    return {
      data: {
        x: "x",
        columns: columns,
        xFormat: "%Y-%m-%dT%H:%M:%S.%LZ"
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: (d: Date) =>
              intl.formatDate(d, {
                year: "numeric",
                month: "short"
              }),
            count: Math.min(numberOfPoints, 3)
          }
        }
      },
      grid: {
        x: {
          lines: [
            {
              value: lastDate
            }
          ]
        },
        y: {
          lines,
          show: true
        }
      },
      legend: {
        show: false
      },
      point: {
        show: true
      },
      tooltip: {
        show: tooltip,
        contents: tooltipContents
          ? function (this: Chart, data) {
              return tooltipContents.apply(this, [data as DataItem[]]);
            }
          : undefined
      },
      zoom: zoomConfiguration
    };
  }, [
    data,
    getLastPointDate,
    getNumberOfPoints,
    intl,
    mapToColumns,
    mapToLines,
    tooltip,
    tooltipContents,
    zoomConfiguration
  ]);

  return configuration;
};

export default useLineChartConfiguration;
